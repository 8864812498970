
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import UpcomingDrawCard from '@/components/dashboard/UpcomingDrawCard.vue';
import PreviousDrawCard from '@/components/dashboard/PreviousDrawCard.vue';
import HeaderImage from '@/components/dashboard/HeaderImage.vue';
import DrawLoaderCard from '@/components/dashboard/DrawLoaderCard.vue';
import { Actions, Getters, Mutations } from '@/store/enums/DrawEnums';
import {
  Actions as SubActions,
  Getters as SubGetters,
} from '@/store/enums/SubscriptionEnums';
import {
  Actions as EntryActions,
  Getters as EntryGetters,
} from '@/store/enums/EntryEnums';
import { Draw } from '@/models/DrawModel';
import { Subscription } from '@/models/SubscriptionModel';
import { Entry } from '@/models/EntryModel';
import * as smartsupp from 'smartsupp-widget';

export default defineComponent({
  components: {
    HeaderImage,
    UpcomingDrawCard,
    PreviousDrawCard,
    DrawLoaderCard,
  },
  data: () => ({
    loadingUpcomingDraws: false,
    loadingPreviousDraws: false,
    subscriptionsData: [] as unknown as Array<Subscription>,
    userEntries: [] as unknown as Array<Entry>,
  }),
  mounted() {
    this.init();

    this.handleSmartSuppName();
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      upcomingDraws: Getters.GET_UPCOMING_DRAWS,
      previousDraws: Getters.GET_PREVIOUS_DRAWS,
      allSubscriptions: SubGetters.GET_ALL_SUBSCRIPTIONS,
      allEntries: EntryGetters.GET_ALL_ENTRIES,
    }),
  },
  methods: {
    ...mapActions({
      fetchUpcomingDraws: Actions.FETCH_UPCOMING_DRAWS,
      fetchPreviousDraws: Actions.FETCH_PREVIOUS_DRAWS,
      fetchSubscriptions: SubActions.FETCH_ALL_SUBSCRIPTIONS,
      fetchEntries: EntryActions.FETCH_USER_ENTRIES,
      addDrawEntries: Actions.ADD_DRAW_ENTRIES,
    }),
    ...mapMutations({
      setDraw: Mutations.SET_DRAW,
    }),
    async init() {
      this.loadingUpcomingDraws = true;
      this.loadingPreviousDraws = true;

      await this.fetchSubscriptions().then(async () => {
        this.subscriptionsData = await this.allSubscriptions;
      });

      await this.fetchEntries().then(async () => {
        this.userEntries = await this.allEntries;
      });

      await this.fetchUpcomingDraws()
        .then(() => {
          this.loadingUpcomingDraws = false;
        })
        .catch(() => {
          this.loadingUpcomingDraws = false;
        });

      await this.fetchPreviousDraws()
        .then(() => {
          this.loadingPreviousDraws = false;
        })
        .catch(() => {
          this.loadingPreviousDraws = false;
        });
    },
    handleJoinDraw(draw: Draw) {
      if (
        typeof this.allSubscriptions !== 'undefined' &&
        this.allSubscriptions?.length
      ) {
        const payload = {
          draw_id: draw.id,
          subscriptions: this.allSubscriptions,
        };
        return this.addDrawEntries(payload)
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            return console.log(error);
          });
      }
      return this.$router.replace({
        name: 'plan-page',
        query: {
          draw: draw.id,
        },
      });
    },

    handleToggleChat() {
      this.handleSmartSuppName();

      smartsupp.chatOpen();
      return false;
    },

    handleSmartSuppName() {
      const firstName = this.authUser.first_name
        ? this.authUser.first_name
        : '';
      const lastName = this.authUser.last_name ? this.authUser.last_name : '';
      const email = this.authUser.email ? this.authUser.email : '';
      const mobile = this.authUser.mobile ? this.authUser.mobile : '';

      const name = firstName && lastName ? `${firstName} ${lastName}` : '';

      smartsupp.setName(name);
      smartsupp.setEmail(email);
      smartsupp.setPhone(mobile);
    },
  },
});
