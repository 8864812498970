
import { defineComponent } from 'vue';
import { Draw } from '@/models/DrawModel';
import { formatDate } from '@/utils/text';
import { Picture as IconPicture } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'previous-draw-card',
  props: {
    draw: {
      type: Object,
      default: () => {
        return {} as unknown as Draw;
      },
    },
    cardClass: {
      type: String,
    },

    progress: {
      type: Number,
    },

    statusDataBadgeColor: {
      type: String,
    },

    statusDataColor: {
      type: String,
    },

    status: {
      type: String,
    },

    icon: {
      type: String,
    },

    title: {
      type: String,
    },

    description: {
      type: String,
    },

    date: {
      type: String,
    },

    budget: {
      type: String,
    },

    users: {
      type: Array,
    },
  },
  components: {
    IconPicture,
  },
  data: () => ({
    readMoreActivated: [] as any,
  }),

  computed: {
    getDrawDateFormatted() {
      return this.draw?.draw_date
        ? formatDate(this.draw?.draw_date, 'aus')
        : 'Not Specified';
    },

    getDrawDate() {
      return this.draw?.draw_date ? this.draw?.draw_date : 'Not Specified';
    },

    getDaysRemaining() {
      return this.draw?.days_left_text
        ? this.draw?.days_left_text
        : 'Coming Soon...';
    },

    getPrize() {
      return this.draw?.prize ? this.draw.prize : '';
    },

    getStatus() {
      return this.draw?.status ? this.draw?.status : 'Active';
    },

    getStatusDataBadgeColor() {
      return this.statusDataBadgeColor
        ? this.statusDataBadgeColor
        : 'badge-light-primary';
    },

    winner() {
      const winner = this.draw?.winners ? this.draw?.winners[0] : null;

      return winner;
    },

    winnerName() {
      const firstName = this.winner ? this.winner.first_name : '';
      const lastName = this.winner ? this.winner.last_name.substring(0, 1) : '';

      return `${firstName} ${lastName}.`;
    },

    winnerState() {
      const state =
        this.winner && this.winner?.state ? ` of ${this.winner.state}` : '';

      return `${state} `;
    },

    winnerAddress() {
      const addressLine =
        this.winner && this.winner?.address_line
          ? this.winner.address_line
          : '';
      const city =
        this.winner && this.winner?.city ? `, ${this.winner.city}` : '';
      const state =
        this.winner && this.winner?.state ? `, ${this.winner.state}` : '';
      const postCode =
        this.winner && this.winner?.post_code
          ? ` ${this.winner.post_code}`
          : '';
      const country =
        this.winner && this.winner?.country ? `, ${this.winner.country}` : '';

      return `${addressLine} ${city} ${state} ${postCode} ${country}`;
    },

    cardImageSrc() {
      const winnerAvatar = this.draw?.winners[0]
        ? this.draw?.winners[0]?.avatar_src
        : this.draw?.draw_image_src;

      return this.draw.winner_image ? this.draw.winner_image_src : winnerAvatar;
    },
  },
  methods: {
    activateReadMore(id) {
      this.readMoreActivated[id] = (!this.readMoreActivated[id] ??
        this.readMoreActivated[id]) as any;
    },

    isReadMore(id) {
      return typeof this.readMoreActivated[id] !== 'undefined'
        ? this.readMoreActivated[id]
        : false;
    },

    toggleCTALabel(id) {
      return typeof this.readMoreActivated[id] !== 'undefined'
        ? ' See Less'
        : ' See More';
    },

    getDescription(size = 0) {
      const defaultDescription = this.draw.description
        ? this.draw.description
        : '';

      const descriptionValue = this.draw.winner_remarks
        ? this.draw.winner_remarks
        : defaultDescription;
      const descStrLen = descriptionValue.length;
      const slice = size > 0 ? size : descStrLen;

      return descriptionValue.slice(0, slice);
    },

    getDrawTimer(date) {
      const now = new Date() as any;
      const drawDate = new Date(date) as any;

      return drawDate - now;
    },
  },
});
