
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';

import { Draw } from '@/models/DrawModel';
import { formatDate } from '@/utils/text';
import { Subscription } from '@/models/SubscriptionModel';
import { Mutations } from '@/store/enums/DrawEnums';
import { Entry } from '@/models/EntryModel';
import moment from 'moment-timezone';
import { Picture as IconPicture } from '@element-plus/icons-vue';
import { User } from '@/models/UserModel';

export default defineComponent({
  name: 'upcoming-draw-card',
  emits: ['joinDraw'],
  props: {
    draw: {
      type: Object,
      default: () => {
        return {} as unknown as Draw;
      },
    },

    subscriptionsEntries: {
      type: Array,
      default: () => {
        return [] as unknown as Array<Subscription>;
      },
    },

    drawEntries: {
      type: Array,
      default: () => {
        return [] as unknown as Array<Entry>;
      },
    },

    user: {
      type: Object,
      default: () => {
        return {} as unknown as User;
      },
    },

    index: {
      type: Number,
    },

    cardClass: {
      type: String,
    },

    progress: {
      type: Number,
    },

    statusDataBadgeColor: {
      type: String,
    },

    statusDataColor: {
      type: String,
    },

    status: {
      type: String,
    },

    icon: {
      type: String,
    },

    title: {
      type: String,
    },

    description: {
      type: String,
    },

    date: {
      type: String,
    },

    budget: {
      type: String,
    },

    users: {
      type: Array,
    },
  },
  components: {
    IconPicture,
  },
  data: () => ({
    subscriptionData: [] as unknown as Array<Subscription>,
    entriesData: [] as unknown as Array<Entry>,
    drawData: {} as unknown as Draw,
    readMoreActivated: [] as any,
    totalEntries: 0,
    remainingText: 'Coming Soon',
  }),
  computed: {
    getDrawDate() {
      return this.draw?.draw_date ? this.draw?.draw_date : 'Not Specified';
    },

    getDrawDateFormatted() {
      const date = this.draw?.draw_date
        ? formatDate(this.draw?.draw_date, 'aus')
        : 'Not Specified';

      const time = this.draw?.draw_time ? this.draw?.draw_time : '';

      return `${date} ${time}`;
    },

    getPrize() {
      return this.draw?.prize
        ? this.draw.prize
        : 'Prize will be indicated soon';
    },

    getStatus() {
      return this.draw?.status ? this.draw?.status : 'Active';
    },

    getStatusDataBadgeColor() {
      return this.statusDataBadgeColor
        ? this.statusDataBadgeColor
        : 'badge-light-primary';
    },

    getCountdown() {
      const { nowDateTime, drawDateTime } = this.drawDateTime();

      if (
        (nowDateTime !== undefined && nowDateTime.isAfter(drawDateTime)) ||
        ['initialized'].includes(this.draw.status)
      ) {
        return 0;
      }

      return this.draw.time_counter
        ? this.draw.time_counter * 24 * 60 * 60 * 1000
        : 0;
    },

    getDaysRemaining() {
      const { nowDateTime, drawDateTime } = this.drawDateTime();

      const timeDiffFromNow = drawDateTime.fromNow();
      const timeDiffDays = drawDateTime.diff(nowDateTime, 'days');

      if (['close'].includes(this.draw.status)) {
        return `Draw has been closed`;
      }

      if (
        ['open'].includes(this.draw.status) &&
        nowDateTime !== undefined &&
        nowDateTime.isAfter(drawDateTime)
      ) {
        return `Draw started ${this.draw?.days_lapse_text}`;
      }

      if (['initialized'].includes(this.draw.status)) {
        return `Preparing to start draw`;
      }

      if (timeDiffDays > 6 && ['open'].includes(this.draw.status)) {
        return `Draw starts ${this.draw?.days_left_text}`;
      }

      return `Draw starts ${timeDiffFromNow}`;
    },

    activeSubscriptionEntries() {
      const quantities = Object.values(this.subscriptionsEntries)
        .map((item) => {
          const subItem = item as unknown as Subscription;
          return parseFloat(subItem.quantity as string);
        })
        .reduce(function (a: any, b: any) {
          return parseFloat(a) + parseFloat(b);
        }, 0);

      return quantities ? quantities : 0;
    },

    additionalEntries() {
      const quantities = Object.values(this.drawEntries)
        .filter((item) => {
          const entryObj = item as unknown as Entry;
          if (
            (entryObj?.type === 'add_entries' &&
              entryObj?.metadata?.draw === this.draw.id &&
              entryObj.user_id === this.user.id) ||
            (entryObj?.type === 'referral' && entryObj.user_id === this.user.id)
          ) {
            return entryObj as unknown as Entry;
          }
        })
        .map((item) => {
          const entryItem = item as unknown as Entry;
          return parseFloat(entryItem.quantity);
        })
        .reduce(function (a: any, b: any) {
          return parseFloat(a) + parseFloat(b);
        }, 0);

      return quantities ? quantities : 0;
    },

    computeTotalEntries() {
      return this.activeSubscriptionEntries + this.additionalEntries;
    },

    hasBundles() {
      return !!this.draw?.bundles.length;
    },
  },
  methods: {
    ...mapMutations({
      setDraw: Mutations.SET_DRAW,
    }),

    drawDateTime() {
      const timezone = process.env.VUE_APP_DEFAULT_TIMEZONE;

      const drawDate = moment(`${this.draw?.draw_date}`).format('YYYY-MM-DD');
      const drawTime = moment(`${this.draw?.draw_time}`, 'hh:mmA').format(
        'hh:mm:ss'
      );

      const drawDateTime = moment(`${drawDate} ${drawTime}`);
      const nowDateTime = moment().tz(timezone);

      return {
        drawDateTime,
        nowDateTime,
      };
    },

    activateReadMore(id) {
      this.readMoreActivated[id] = (!this.readMoreActivated[id] ??
        this.readMoreActivated[id]) as any;
    },

    isReadMore(id) {
      return typeof this.readMoreActivated[id] !== 'undefined'
        ? this.readMoreActivated[id]
        : false;
    },

    toggleCTALabel(id) {
      return typeof this.readMoreActivated[id] !== 'undefined'
        ? ' See Less'
        : ' See More';
    },

    getDescription(size = 0) {
      const descriptionValue = this.draw.description
        ? this.draw.description
        : 'Draw details will be posted soon.';
      const descStrLen = descriptionValue.length;
      const slice = size > 0 ? size : descStrLen;

      return descriptionValue.slice(0, slice);
    },

    getDrawTimer(date) {
      const now = new Date() as any;
      const drawDate = new Date(date) as any;

      return drawDate - now;
    },

    joinDraw() {
      return this.$emit('joinDraw', this.draw);
    },

    async viewDraw() {
      if (!this.hasBundles) return;

      this.setDraw(this.draw);

      this.$router.push({
        name: 'view-draw',
        params: {
          draw_id: this.draw.id,
        },
      });
    },

    handleCountdownProgress({
      days,
      hours,
      minutes,
      seconds,
      totalMilliseconds,
    }) {
      if (!this.draw.time_counter) return;

      let timeText = '';
      if (days > 1) {
        timeText = `${days} days`;
      }

      if (!days && hours > 1) {
        timeText = `${hours} hours`;
      }

      if (!days && !hours && minutes > 1) {
        timeText = `${minutes} minutes`;
      }

      if (!days && !hours && !minutes && seconds != 0) {
        timeText = `${seconds} seconds`;
      }

      if (totalMilliseconds > 0) {
        this.remainingText = `in ${timeText}`;
      }
    },

    handleCountdownEnd() {
      if (!this.draw.time_counter) return;

      this.remainingText = `Draw is being prepared for raffle`;
    },
  },
});
